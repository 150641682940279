@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@600;700;800;900&family=Poppins:wght@300;400;500;600;700;800;900');
@import url(https://db.onlinewebfonts.com/c/30b3915fcf2263f0686f82f7bb06cfbb?family=Bernoru+Black+Ultra+Expanded);
@import url(https://db.onlinewebfonts.com/c/6f066397e454197f3d591c8edbe370ee?family=COCOMAT+Light);


:root {
  --color-primary: #6f6af8;
  --color-white: #fff;
  --color-red: rgb(255, 63, 63);
  --color-gray-100: hsl(220, 20%, 10%, 5%);
  --color-gray-200: hsl(220, 20%, 10%, 10%);
  --color-gray-300: hsl(220, 20%, 10%, 25%);
  --color-gray-500: hsl(220, 20%, 10%, 50%);
  --color-gray-700: #252542;
  --color-gray-900: #0c0c22;
  --color-navy: #002564;
  --color-violet: #573680;
  --color-bg: #061f5c;

  --transition: all 300ms ease;

  --container-width-lg: 70%;
  --container-width-md: 92%;
  --form-width: 50%;

  --radius-1: 0.3rem;
  --radius-2: 0.6rem;
  --radius-3: 1rem;
  --radius-4: 2rem;
}

::-webkit-scrollbar {
  display: none;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  background: var(--color-bg);
  color: white !important;
  background-image: url('./images/banniere-fond.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
  color: var(--color-gray-700);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

a {
  font-family: "Poppins", sans-serif;
  color: var(--color-white);
  text-decoration: none;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "COCOMAT Light";
  color: var(--color-white);
  line-height: 1.1;
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.9rem;
}

h3 {
  font-size: 1.3rem;
  line-height: 1.25;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  line-height: 1.4;
}

section {
  margin-top: 6rem;
}

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container {
  width: var(--container-width-lg);
  margin-inline: auto;
}





.btn {
  display: inline-block;
  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: var(--radius-2);
  background: var(--color-white);
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
}

.btn.primary {
  background: var(--color-violet);
  color: var(--color-white);
}

.btn.secondary {
  background: var(--color-white);
  color: var(--color-navy);
}

.btn.validate {
  background: rgb(0, 188, 13);
  color: var(--color-white);
}

.btn.category {
  background: var(--color-violet);
  padding: 0.2rem 0.5rem;
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
}

.btn.sm {
  font-size: 0.8rem;
  padding: 0.3rem 0.7rem;
  border-radius: 0.4rem;
}

.btn.danger {
  background: var(--color-red);
  color: var(--color-white);
}

.btn.primary:hover {
  background: var(--color-white);
  color: var(--color-navy);
}

.btn.secondary:hover {
  background: var(--color-violet);
  color: var(--color-white);
}

.btn.danger:hover {
  background: #b00000;
}

.btn.validate:hover {
  background: green;
}

.btn.category:hover {
  background: var(--color-white);
  color: var(--color-navy);
}




.form {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

input,
select,
textarea {
  width: 100%;
  padding: 0.6rem 1rem;
  border-radius: 0.3rem;
  resize: none;
}

.featured__wrapper {
  display: flex;
  gap: 0.5rem;
}

label,
input[type="checkbox"] {
  width: fit-content;
}

label {
  font-size: 0.9rem;
}

.form__error-message {
  background: var(--color-red);
  color: var(--color-white);
  font-size: 0.8rem;
  padding: 0.6rem 1rem;
  border-radius: var(--radius-1);
  display: block;
  margin-bottom: 1rem;
}








/* fix for ql editor overflow */
.ql-editor {
  overflow: scroll;
  height: 20rem;
  max-height: 50rem;
}

.center {
  text-align: center;
  padding-top: 5rem;
  margin-inline: center;
  display: block;
  width: 100%;
}

/* little style on error page */

.error-page {
  height: 80vh;
  width: 100vw;
}


.error-page h2 {
  margin-bottom: 2em;
}








/*======================== NAV ========================*/
nav {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  place-items: center;
  margin-top: 5em;
}

.nav__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav__logo {
  max-width: 500px;
  width: 60%;
  margin-bottom: 1em;
}

.nav__menu {
  display: flex;
  align-items: center;
  gap: 3rem;
  font-weight: bold;
}

.nav__menu p {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.nav__menu img {
  width: 25px;
}

.nav__toggle-btn {
  display: none;
}


/*======================== SOCIALS//SUPORT ========================*/

.socials__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  font-size: large;
  text-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 1);
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: var(--form-width);
}

.socials img{
  width: 60px;
}

.kofi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  gap: 1rem;
}

.kofi img {
  width: 100px;
}

.support__page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  gap: 1rem;
  width: var(--form-width);
}

.support__page img {
  width: 100px;
}

.support__page p, 
.support__page h1 {
  text-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 1);
}

.support__page p {
  font-size: large;
}

/*======================== HOME ========================*/
/*======================== Posts ========================*/


.posts {
  display: flex;
  flex-direction: row;
}

.posts__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 60%;
}

.post {
  background: var(--color-navy);
  padding: 1rem;
  border-radius: var(--radius-3);
  transition: var(--transition);
  cursor: default;
  box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.post p a {
  text-decoration: underline;
}

.post__thumbnail {
  border-radius: var(--radius-3);
  overflow: hidden;
  height: 16rem;
}

.post__content h3 {
  margin: 0rem 0 0.6rem;
}

.post__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2rem;
}

.post__author {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.post__author-avatar {
  width: 2.5rem;
  aspect-ratio: 1/1;
  border-radius: var(--radius-4);
  overflow: hidden;
}


/*======================== CATEGORIES ========================*/

.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: fixed;
  width: 14%;
  padding: 1rem;
  margin-left: 3rem;
  background-color: var(--color-navy);
  border-radius: 10px;
  box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.3);

}

.categories__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}



/*======================== LOADER ========================*/
.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: var(--color-bg);
  display: grid;
  place-items: center;
}

.loader__image {
  width: 4.5rem;
  height: 4.5rem;
}









/*======================== POST DETAILS ========================*/
.post-detail {
  width: var(--container-width-lg);
  background: var(--color-navy);
  margin-inline: auto;
  padding: 2rem 0;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-radius: var(--radius-3);
  font-size: large;
}

.post-detail__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.post-detail__buttons {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.post-detail__thumbnail {
  margin: 1rem 0;
  height: fit-content;
  max-height: 30rem;
  overflow: hidden;
}

.post-detail__container p {
  margin-bottom: 0.7rem;
}











/*======================== FOOTER ========================*/
footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--color-bg);
  margin-top: 6rem;
  padding: 1rem 2rem;
}

footer img {
  width: 15rem;
}

.footer__categories {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 4rem;
}

.footer__categories a {
  background: var(--color-violet);
  color: var(--color-white);
  padding: 0.6rem 1.4rem;
  border-radius: var(--radius-2);
  transition: var(--transition);
}

.footer__categories a:hover {
  background: var(--color-white);
  color: var(--color-navy);
}

.footer__copyright {
  text-align: center;
  padding: 1rem;
  color: var(--color-white);
  font-weight: 300;
}

footer h2 {
  margin-bottom: 1em;
}


.social__links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem
}

.social__links img {
  width: 30px;
}





/*======================== REGISTER  && GENERAL FORM ========================*/
.register,
.login {
  width: var(--form-width);
  height: 20vh;
  margin-inline: auto;
  display: grid;
  place-items: center;
}

.create-post {
  height: 100%;
  margin-bottom: 3rem;
}

.register h2,
.login h2,
.create-post h2 {
  margin-bottom: 2rem;
}

.register small,
.login small {
  display: block;
  margin-top: 1.5rem;
}

.register small a,
.login small a {
  color: var(--color-white);
  text-decoration: underline;
}

/* change the background of quill editor */
.ql-editor {
  background: var(--color-navy);
  color: white;
  height: 150em;
}

.ql-toolbar {
  background-color: rgb(255, 255, 255);
}

.ql-editor a {
  color: white;
  cursor: pointer;
  text-decoration: underline !important;
}




/*======================== ABOUT ========================*/

.about__container {
    display: flex;
    flex-direction: column;
    font-size: large;
    gap: 3rem;
    width: var(--form-width);
    margin-inline: auto;
    height: 60vh;
    margin-top: 5rem;
    margin-bottom: 3rem;
    padding: 2rem;
    border-radius: var(--radius-3);
  
}

.about__container h1, .about__container p {
  text-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 1);
} 



/*======================== USER PROFILE ========================*/

.profile {
  width: var(--container-width-lg);
  height: 100vh;
}

.profile__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.profile__details {
  width: 100%;
  height: 60vh;
}

.avatar__wrapper {
  width: 10rem;
  aspect-ratio: 1/1;
  position: relative;
  margin-inline: auto;
  margin-top: 1rem;
}

.profile__avatar {
  height: 100%;
  border-radius: 50%;
  border: 0.5rem solid var(--color-white);
  overflow: hidden;
}

.avatar__form {
  height: 1rem;
}

.avatar__form input {
  visibility: hidden;
}

.avatar__form label,
.profile__avatar-btn {
  background: var(--color-violet);
  cursor: pointer;
  color: var(--color-white);
  font-size: 1.2rem;
  position: absolute;
  right: 2rem;
  bottom: 1.4rem;
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
}

.profile__avatar-btn {
  cursor: pointer;
  padding: 0;
  font-size: 2rem;
  background: var(--color-violet);
}

.profile__details h1 {
  margin-top: 1rem;
}

.form.profile__form {
  margin-top: 2rem;
  margin-inline: auto;
  width: var(--form-width);
}









/*======================== AUTHORS ========================*/
.authors__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
}

.author {
  background: var(--color-white);
  padding: 1rem;
  border-radius: var(--radius-3);
  display: flex;
  gap: 1.3rem;
  transition: var(--transition);
}

.author:hover {
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.07);
}

.author__avatar {
  width: 5rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 0.3rem solid var(--color-bg);
  overflow: hidden;
}

.author__info p {
  margin-top: 1rem;
}











/*======================== DASHBOARD ========================*/


.dashboard {
  height: 100vh;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-bottom: 3rem;
}



.dashboard__post {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: var(--color-white);
  padding: 1rem 1rem;
  border-radius: 0.5rem;
}

.dashboard__post-info {
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: center;
}

.dashboard__post-title {
  color: #002564;
}

.dashboard__post-thumbnail {
  width: 12rem;
  border-radius: 0.3rem;
  overflow: hidden;
}

.dashboard__post-actions {
  display: flex;
  gap: 1rem;
}

.dashboard__post-info h5{
  color: var(--color-navy);
  font-size: large;
  font-weight: bold;
}

/*======================== MEDIA QUERIES (tablets)) ========================*/



@media screen and (max-width: 1600px) {

  .posts__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: var(--container-width-md);
  }

  .posts {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .categories {
    position: relative;
    border: none;
    background-color: transparent;
    margin: 0;
    width: 100%;
    box-shadow: none;
  }

  .categories__links {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  footer {
  flex-direction: column;
  }
}




/*======================== MEDIA QUERIES (phones) ========================*/

@media screen and (max-width: 480px) {
  .nav__logo {
    width: 80%;
  }

  .about__container {
    height: 100vh;
  }
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  .container {
    width: var(--container-width-md);
  }

  section {
    margin: 0px;
  }


  /* NAVBAR */
  .nav__container {
    width: var(--container-width-md);
    position: relative;
    flex-direction: row;
    top: -40px;
  }

  .nav__menu {
    position: absolute;
    z-index: 1000;
    flex-direction: column;
    top: 5rem;
    right: 0;
    gap: 0;
    perspective: 350px;
  }

  .nav__menu li {
    width: 100%;
    box-shadow: -2rem 2rem 4rem rgba(0, 0, 0, 0.3);
    animation: dropDown 500ms ease forwards;
    opacity: 0;
    transform: rotateX(90deg);
    transform-origin: top;
  }

  .nav__logo {
    margin: 0px;
  }


  @keyframes dropDown {
    to {
      opacity: 1;
      transform: rotateX(0);
    }
  }

  .nav__menu li:nth-child(2) {
    animation-delay: 100ms;
  }

  .nav__menu li:nth-child(3) {
    animation-delay: 200ms;
  }

  .nav__menu li:nth-child(4) {
    animation-delay: 300ms;
  }

  .nav__menu li a {
    display: block;
    background: var(--color-navy);
    padding: 1rem 2.5rem;
  }

  .nav__toggle-btn {
    display: inline-block;
    color: white;
    background: transparent;
    font-size: 1.5rem;
    cursor: pointer;
  }




  /* Footer */

  footer {
    border-top: 2px solid var(--color-violet);
    flex-direction: column;
  }

  .footer__categories {
    flex-direction: row;
    width: var(--container-width-md);
  }


  /* Posts */
  .posts__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }


  /* Single Post Detail */
  .post-detail {
    width: 97%;
  }


  /* Register, Login, Create & Edit post */
  .register,
  .login,
  .create-post {
    width: 100%;
  }

  .profile__details h1 {
    margin-top: 1rem;
  }

  .form.profile__form {
    margin-top: 2.5rem;
    width: 80%;
  }



  /* Authors Page */
  .authors__container {
    grid-template-columns: 1fr;
    gap: 1.2rem;
  }



  /* Dashboard Page */
  .dashboard__post {
    flex-direction: column;
  }

  .dashboard__post-info {
    gap: 1.5rem;
  }

  .dashboard__post-thumbnail {
    width: 5rem;
  }

  /*About*/

  .about__container{
    width: var(--container-width-md);
    margin-top: 0rem;
    
  }

  .socials {
    width: var(--container-width-md);
  }

  .socials img{
    width: 40px;
  }

  .kofi img {
    width: 70px;
  }
  
}